import React from 'react';
import { graphql } from 'gatsby';

import { Grid } from '@material-ui/core';
import { ContentfulDiarioInternacional } from '../../types/graphql-types';
import { useDiarioInternacionalPost } from '../operations/queries/diarioInternacionalPost';
import PostLayout from '../components/PostLayout';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { DiarioInternacional as DiarioInternacionalProps } from '../models/DiarioInternacional';
import IconTitle from '../components/IconTitle';
import { useTheme } from '@material-ui/core/styles';

import WorldMarkets from '../assets/icons/icon-WorldMarkets.svg';


import useMediaQuery from '@material-ui/core/useMediaQuery';

import getCategoryColor from '../utils/getCategoryColor';

import { renderRichText } from "gatsby-source-contentful/rich-text"

import options from '../utils/getRichTextOptions';

import USA from '../assets/icons/icon-USA.svg';
import CHINA from '../assets/icons/icon-china.svg';
import UK from '../assets/icons/icon-uk.svg';



interface Props {
  data: {
    contentfulDiarioInternacional: ContentfulDiarioInternacional;
  };
}

export default function DiarioInternacional({ data }: Props): JSX.Element {
  const post: DiarioInternacionalProps = useDiarioInternacionalPost(
    data.contentfulDiarioInternacional
    );
    // console.log(post)

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      content,
      tags,
      diarioInternacionalPaises
    } = post;

    // const description = Document;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      content,
      tags,
      diarioInternacionalPaises
    };
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const color = getCategoryColor('internacional');

  // console.log('post diario econ');
  // console.log(post);
  // const gatsbyImage = getImage(post.fluid2)

  return (
    <>
      <PostLayout post={postPreview()} color={color}>
        <>
          {post.description && (
            <h2
              style={{
                paddingRight: '10px',
                paddingLeft: '10px',
                fontSize: '1.15em',
              }}
            >
              {/* {renderRichText(post.description, options)} */}
              {post.description}
            </h2>
          )}
          <>
            <Grid container>

              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={mobile ? { zIndex: 0, background: 'white' } : {}}
              >
                {post.textEUA && (
                  <IconTitle title={post.subheaderEUA} icon={USA}>
                    {renderRichText(post.textEUA, options)}
                  </IconTitle>
                )}
                {post.imagesEUA && (
                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    {/* <Image fluid={post.fluid2} alt="slide-1" /> */}
                    {/* <GatsbyImage image={post.imagesEUA.gatsbyImageData.images.fallback.src} alt="slide-1"/> */}
                    <img src={post.imagesEUA.gatsbyImageData.images.fallback.src} alt="" />
                  </div>
                )}

                {post.textChina && (
                  <IconTitle title={post.subheaderChina} icon={CHINA}>
                    {renderRichText(post.textChina, options)}
                  </IconTitle>
                )}
                {post.imagesChina && (
                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <img src={post.imagesChina.gatsbyImageData.images.fallback.src} alt="" />
                  </div>
                )}


                {post.textUk && (
                  <IconTitle title={post.subheaderUk} icon={UK}>
                    {renderRichText(post.textUk, options)}
                  </IconTitle>
                )}
                {post.imagesUk && (
                  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <img src={post.imagesUk.gatsbyImageData.images.fallback.src} alt="" />
                  </div>
                )}

                

                {post.diarioInternacionalPaises && 
                  
                  post.diarioInternacionalPaises.map(function (node:any) {
                    return (
                      <>
                        <IconTitle title={node.subheaderPais} icon={WorldMarkets}>
                          {renderRichText(node.textoPais, options)}
                        </IconTitle>
                      
                      
                        <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                          <img src={node.imagenPais.gatsbyImageData.images.fallback.src} alt="" />
                        </div>
                      </>
                    );
                  })
                  
                }
                
              </Grid>
            </Grid>
          </>
        </>
      </PostLayout>
    </>
  );
}

export const pageQuery = graphql`
  query {
    contentfulDiarioInternacional(slug: { eq: "titulo-diario-internacional" }) {
      ...diarioInternacional
    }
  }
`;
